import React from "react";
import { StaticQuery, graphql } from "gatsby";
import url from "url";

import config from "~/utils/siteConfig";

import ArticleMeta from "./ArticleMeta";
import WebsiteMeta from "./WebsiteMeta";
import AuthorMeta from "./AuthorMeta";

type Props = {
  data: {
    ghostPost: Record<string, unknown>;
    ghostTag: Record<string, unknown>;
    ghostAuthor: Record<string, unknown>;
    ghostPage: Record<string, unknown>;
  };
  settings?: {
    allGhostSettings: Record<string, unknown>;
    [key: string]: unknown;
  };
  location: Location;
  title: string;
  description: string;
  image: string;
};

/**
 * MetaData will generate all relevant meta data information incl.
 * JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
 */
const MetaData = ({
  data,
  settings,
  title,
  description,
  image,
  location
}: Props) => {
  const canonical = url.resolve(config.siteUrl, location.pathname);
  const { ghostPost, ghostTag, ghostAuthor, ghostPage } = data;
  settings = settings?.allGhostSettings?.edges?.[0]?.node;

  if (ghostPost) {
    return <ArticleMeta data={ghostPost} canonical={canonical} />;
  } else if (ghostTag) {
    return <WebsiteMeta data={ghostTag} canonical={canonical} type="Series" />;
  } else if (ghostAuthor) {
    return <AuthorMeta data={ghostAuthor} canonical={canonical} />;
  } else if (ghostPage) {
    return (
      <WebsiteMeta data={ghostPage} canonical={canonical} type="WebSite" />
    );
  } else {
    title = title || config.siteTitleMeta || settings?.title;
    description =
      description || config.siteDescriptionMeta || settings?.description;
    image = image || (settings?.cover_image as string) || null;

    image = image ? url.resolve(config.siteUrl, image) : null;

    return (
      <WebsiteMeta
        data={{}}
        canonical={canonical}
        title={title}
        description={description}
        image={image}
        type="WebSite"
      />
    );
  }
};

MetaData.defaultProps = {
  data: {}
};

const MetaDataQuery = (props) => (
  <StaticQuery
    query={graphql`
      query GhostSettingsMetaData {
        allGhostSettings {
          edges {
            node {
              title
              description
            }
          }
        }
      }
    `}
    render={(data) => <MetaData settings={data} {...props} />}
  />
);

export default MetaDataQuery;
